<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
              <b-col lg="12" sm="6">
                <h6 class="text-center mb-2">Informações do Idoso</h6>
                <hr>
              </b-col>
            </b-row>
          <b-row>
            <!-- Info do idoso -->
            <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">Nome</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{elderly.elderly.user.name}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AtSignIcon" class="mr-75" />
                      <span class="font-weight-bold">E-mail</span>
                    </th>
                    <td class="pb-50">
                       {{elderly.elderly.user.email}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CreditCardIcon" class="mr-75" />
                      <span class="font-weight-bold">CPF / CNPJ</span>
                    </th>
                    <td class="pb-50">{{elderly.elderly.user.cpf_cnpj}}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="PhoneIcon" class="mr-75" />
                      <span class="font-weight-bold">Telefone</span>
                    </th>
                    <td class="pb-50" v-if="!elderly.elderly.user.cell_phone">Não informado</td>
                    <td class="pb-50" v-else>{{elderly.elderly.user.cell_phone}}</td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">Desde</span>
                    </th>
                    <td>{{elderly.elderly.user.created_at | dateFormat}}</td>
                  </tr>
                </table>
              </b-col>
              <!-- Endereço -->
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="HomeIcon" class="mr-75" />
                      <span class="font-weight-bold">Rua e Numero</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                     {{elderly.elderly.user.address.street}} - Nº  {{elderly.elderly.user.address.number}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      <span class="font-weight-bold">CEP</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{elderly.elderly.user.address.zip_address}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="PlusIcon" class="mr-75" />
                      <span class="font-weight-bold">Complemento</span>
                    </th>
                    <td class="pb-50" v-if="!elderly.elderly.user.address.complement">Não informado </td>
                    <td class="pb-50" v-else>{{elderly.elderly.user.address.complement}}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CompassIcon" class="mr-75" />
                      <span class="font-weight-bold">Cidade</span>
                    </th>
                    <td class="pb-50">{{elderly.elderly.user.address.city}}</td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="NavigationIcon" class="mr-75" />
                      <span class="font-weight-bold">Estado</span>
                    </th>
                    <td>{{elderly.elderly.user.address.state}}</td>
                  </tr>
                </table>
              </b-col>
          </b-row>
          <b-row>
              <b-col lg="12" sm="6">
                <hr class="mb-2">
              </b-col>
            </b-row>
          <b-row>
              <b-col lg="6" sm="6">
                <b-row>
                  <b-col>
                    <b-list-group>
                      <b-list-group-item>Apresenta limitações? <b>{{elderly.elderly.has_limitations == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>Utiliza algum tipo de auxilio? <b>{{elderly.elderly.uses_some_kind_of_help == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>Pode andar? <b>{{elderly.elderly.can_walk == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>Tem autocuidado? <b>{{elderly.elderly.has_selfcare == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>Precisa de cuidados especiais? <b>{{elderly.elderly.needs_special_care == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>Precisa de ajuda com pequenas tarefas? <b>{{elderly.elderly.needs_help_with_small_tasks == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>Está acamado? <b>{{elderly.elderly.is_bedridden == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                      <b-list-group-item>É cadeirante? <b>{{elderly.elderly.is_wheelchair == '1' ? 'Sim' : "Não"}}</b></b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="6" sm="6">
                <b-row>
                  <b-col lg="12" sm="6">
                      <div
                        no-body
                        class="card-developer-meetup"
                      >
                        <div class="bg-light-primary rounded-top text-center">
                          <b-img
                            :src="imgUrl"
                            alt="Meeting Pic"
                            height="170"
                          />
                        </div>
                        <h4 class="mt-5 text-center text-danger" v-if="!elderly.elderly.user.schedule[0]">Nenhum evento previsto para hoje!</h4>
                      <b-card-body v-else>
                      <!-- metting header -->
                        <div class="meetup-header d-flex align-items-center">
                          <div class="meetup-day">
                            <h6 class="mb-0">
                              Dia
                            </h6>
                            <h3 class="mb-0">
                             {{elderly.elderly.user.schedule[0].date | dayFormat }}
                            </h3>
                          </div>
                          <div class="my-auto">
                            <b-card-title class="mb-25">
                             {{elderly.elderly.user.schedule[0].title}} às {{elderly.elderly.user.schedule[0].time | hourFormat}}
                            </b-card-title>
                          </div>
                        </div>
                        <!--/ metting header -->

                        <!-- media -->
                        <b-media
                          no-body
                        >
                          <b-media-aside class="mr-1">
                            <b-avatar
                              rounded
                              variant="light-primary"
                              size="34"
                            >
                            <feather-icon
                              icon="CalendarIcon"
                              size="18"
                            />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            <h6 class="mb-0">
                             {{elderly.elderly.user.schedule[0].title}}
                            </h6>
                            <small>{{elderly.elderly.user.schedule[0].description}} às {{elderly.elderly.user.schedule[0].time | hourFormat}}</small>
                          </b-media-body>
                        </b-media>
                        <b-media
                          no-body
                        >
                          <b-media-aside class="mr-1">
                            <b-avatar
                              rounded
                              variant="light-primary"
                              size="34"
                            >
                            <feather-icon
                              icon="MapPinIcon"
                              size="18"
                            />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            <h6 class="mb-0">
                              {{elderly.elderly.user.schedule[0].localization}}
                            </h6>
                          </b-media-body>
                        </b-media>

                      <!-- avatar group -->
                      </b-card-body>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
              <b-col lg="12" sm="6">
                <h6 class="text-center mb-2">Informações do Responsavel</h6>
                <hr>
              </b-col>
            </b-row>
          <b-row>
            <!-- Info do idoso -->
            <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">Nome</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{elderly.responsible.name}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AtSignIcon" class="mr-75" />
                      <span class="font-weight-bold">E-mail</span>
                    </th>
                    <td class="pb-50">
                       {{elderly.responsible.email}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CreditCardIcon" class="mr-75" />
                      <span class="font-weight-bold">CPF / CNPJ</span>
                    </th>
                    <td class="pb-50">{{elderly.responsible.cpf_cnpj}}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="PhoneIcon" class="mr-75" />
                      <span class="font-weight-bold">Telefone</span>
                    </th>
                    <td class="pb-50" v-if="!elderly.responsible.cell_phone">Não informado</td>
                    <td class="pb-50" v-else>{{elderly.responsible.cell_phone}}</td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">Desde</span>
                    </th>
                    <td>{{elderly.responsible.created_at | dateFormat}}</td>
                  </tr>
                </table>
              </b-col>
              <!-- Endereço -->
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="HomeIcon" class="mr-75" />
                      <span class="font-weight-bold">Rua e Numero</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                     {{elderly.responsible.address.street}} - Nº {{elderly.responsible.address.number}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      <span class="font-weight-bold">CEP</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{elderly.responsible.address.zip_address}}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="PlusIcon" class="mr-75" />
                      <span class="font-weight-bold">Complemento</span>
                    </th>
                    <td class="pb-50" v-if="!elderly.responsible.address.complement">Não informado</td>
                    <td class="pb-50" v-else>{{elderly.responsible.address.complement}}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CompassIcon" class="mr-75" />
                      <span class="font-weight-bold">Cidade</span>
                    </th>
                    <td class="pb-50">{{elderly.responsible.address.city}}</td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="NavigationIcon" class="mr-75" />
                      <span class="font-weight-bold">Estado</span>
                    </th>
                    <td>{{elderly.responsible.address.state}}</td>
                  </tr>
                </table>
              </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col lg="12" sm="6">
              <h6 class="text-center mb-2">Receitas Medicas</h6>
              <hr>
            </b-col>
          </b-row>
          <b-table-simple
            striped
            stacked="md"
            class="mt-2 responsive text-center"
          >
            <b-thead>
              <b-tr>
                <b-th>Medicamento</b-th>
                <b-th>Frequência </b-th>
                <b-th>Quantidade</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in elderly.prescription">
                <b-td>{{tr.medicament.name}} - {{tr.medicament.dosage}}</b-td>
                <b-td>{{tr.frequency_type.name}}</b-td>
                <b-td>{{tr.amount_type.name}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import store from '@/store/index'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sideImg: require('@/assets/images/email.svg'),
      isLoading: true
    }
  },
  computed: {
    ...mapState("elderlys", ["elderly"]),
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/email.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions("elderlys", ["fetchDetails"]),
  },
  created(){
    this.fetchDetails({
      elderly_id: this.$route.params.id,
    });
  },
  watch: {
    elderly(){
      this.isLoading = false
    }
  }
}
</script>
